import { memo } from 'react'
import { useTheme } from '@emotion/react'
import { Send } from 'react-feather'

import { Flex, Text } from '@prostpost/uikit'

type Props = {
	time: string | null
}

const arePropsEqual = (prevProps: Props, nextProps: Props) => prevProps.time === nextProps.time

export const Time = memo(function Time({ time }: Props) {
	const theme = useTheme()

	const iconProps = {
		size: 16,
		strokeWidth: 2.5,
		color: theme.colors.black_80,
	}

	return (
		<Flex align="center">
			<Send {...iconProps} />
			<Text noWrap ml={1} mr={1} size={14} color="black_80">
				{time}
			</Text>
		</Flex>
	)
}, arePropsEqual)
