import { useState } from 'react'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { HStack } from '@prostpost/uikit'
import { notReachable } from '@prostpost/utils'

import { useIsAllowedToUnpublishOrEdit } from 'app/domains/Post/hooks'
import { CancelPostEditing } from 'app/domains/Post/features/CancelPostEditing'
import type { PostInternal } from 'app/domains/Post'
import type { DraftNotScheduled } from 'app/domains/Draft'
import type { ChannelActive } from 'app/domains/Channel'

import {
	Header,
	Overlay,
	Views,
	Wrapper,
	SilentMark,
	AdTag,
	TextAndImages,
	Reactions,
	ContextMenuBadge,
} from '../../components'

import { Time } from './Time'
import { ContextMenu } from './ContextMenu'

type Msg =
	| { type: 'on_unpublished' }
	| { type: 'on_click_unpublish' }
	| { type: 'on_unpublish_failed' }
	| { type: 'on_click_edit' }
	| { type: 'on_click_copy_as_draft'; draft: DraftNotScheduled }
	| { type: 'on_start_cleaning_backup' }
	| { type: 'on_backup_cleaned'; postUuid: string }
	| { type: 'on_click_load_unpublished_version'; backupUuid: string }
	| { type: 'on_click_open_modal_editor'; postUuid: string }
	| { type: 'on_click_open_in_telegram' }

type Props = {
	post: PostInternal
	channel: ChannelActive
	timeFormat: '12h' | '24h'
	selection: 'editing' | 'none' | 'readonly'
	isDisabled: boolean
	isLoading: boolean
	isImageVisible: boolean
	channelsCount: number
	onMsg: (msg: Msg) => void
}

export const Internal = observer(function PostPreview({
	selection = 'none',
	isLoading = false,
	isDisabled = false,
	isImageVisible = true,
	channelsCount,
	post,
	channel,
	timeFormat,
	onMsg,
}: Props) {
	const isEditing = selection === 'editing'
	const canPostBeEdited = useIsAllowedToUnpublishOrEdit(post)

	const [isContextOpen, setIsContextOpen] = useState(false)
	const [isCancelEditingModalOpen, setIsCancelEditingModalOpen] = useState(false)

	return (
		<Wrapper
			isSelectable={!isDisabled || isEditing}
			isHover={isContextOpen}
			isFailedToPublish={false}
			isSelected={isEditing}
			onClick={() => {
				if (isEditing || isDisabled || isLoading) return
				runInAction(() => {
					onMsg({ type: 'on_click_open_modal_editor', postUuid: post.uuid })
				})
			}}
		>
			{isLoading ? <Overlay isLoading /> : null}
			{isEditing || isDisabled ? <Overlay isLoading={false} /> : null}

			<Header channelsCount={channelsCount} channel={channel}>
				<Header.Time post={post} timeFormat={timeFormat}>
					{({ formattedTime }) => <Time time={formattedTime} />}
				</Header.Time>

				{isEditing || isDisabled || isLoading || !post.unpublishedVersion ? null : canPostBeEdited ? (
					<ContextMenuBadge />
				) : null}

				{isEditing ? (
					<CancelPostEditing.Trigger
						variant="inline"
						isPostPublishingFailed={false}
						onClick={() => setIsCancelEditingModalOpen(true)}
					/>
				) : (
					<>
						<HStack space={2} mr="auto" ml={2}>
							{post.ad ? <AdTag /> : null}
							{post.silent ? <SilentMark /> : null}
							<Views views={post.views} />
						</HStack>

						<ContextMenu
							post={post}
							onMsg={msg => {
								switch (msg.type) {
									case 'on_unpublished':
									case 'on_click_edit':
									case 'on_click_unpublish':
									case 'on_unpublish_failed':
									case 'on_click_load_unpublished_version':
									case 'on_click_open_in_telegram':
									case 'on_click_copy_as_draft':
										onMsg(msg)
										break
									case 'on_context_menu_toggle':
										setIsContextOpen(msg.isOpen)
										break
									case 'on_click_open_modal_editor':
										runInAction(() => {
											onMsg({ type: 'on_click_open_modal_editor', postUuid: post.uuid })
										})
										break
									default:
										notReachable(msg)
								}
							}}
						/>
					</>
				)}
			</Header>

			<TextAndImages post={post} isImageVisible={isImageVisible} />
			{post.reactions ? (
				<HStack space={2} mt={2}>
					<Reactions reactions={post.reactions} />
				</HStack>
			) : null}

			<CancelPostEditing
				isOpen={isCancelEditingModalOpen}
				draftUuid={post.uuid}
				onMsg={msg => {
					switch (msg.type) {
						case 'on_backup_cleaned':
							onMsg(msg)
							break
						case 'on_start_cleaning_backup':
							setIsCancelEditingModalOpen(false)
							onMsg(msg)
							break
						case 'on_cancel_editing_modal_closed':
							setIsCancelEditingModalOpen(false)
							break
						default:
							notReachable(msg)
					}
				}}
			/>
		</Wrapper>
	)
})
