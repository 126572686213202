import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import * as Icons from 'lucide-react'

import { truncate } from '@prostpost/utils'
import { Text, HStack } from '@prostpost/uikit'

const StyledLabel = styled(HStack)`
	width: fit-content;
	padding: 3px 6px;
	border-radius: 12px;
	border: 1px solid #d9e6ff;
	background: #f3f7ff;
`

type Props = {
	title: string
}

export const ForwardFrom = ({ title }: Props) => {
	const theme = useTheme()
	return (
		<StyledLabel space={1} align="center" just="center">
			<Icons.Repeat2 size={12} strokeWidth={2.5} color={theme.colors.blue_40} />
			<Text size={12} color="blue_40">
				{truncate(title, 12)}
			</Text>
		</StyledLabel>
	)
}
