import { useQuery } from 'react-query'
import { useTheme } from '@emotion/react'
import Lottie from 'lottie-react'
import * as Icons from 'lucide-react'

import { Box, Flex } from '@prostpost/uikit'

import { config } from 'app/config'

type Props = {
	size?: number
	emojiId: string
	loopAnimation?: boolean
}

export const TelegramAnimatedEmoji = ({ emojiId, size = 16, loopAnimation = true }: Props) => {
	const theme = useTheme()
	const { data: emojiData, isSuccess } = useQuery({
		enabled: !!emojiId,
		queryKey: ['emoji', emojiId],
		queryFn: () =>
			config.network.proxy
				.get(
					`${config.constants.MEDIA_TELEGRAM_EMOJI_URL}/${emojiId}.${config.constants.MEDIA_TELEGRAM_EMOJI_EXT}`,
					{ baseURL: '' }, // override "/api" base URL
				)
				.then(({ data }) => data),
	})

	return isSuccess && emojiData ? (
		<Box
			mx="2px"
			as="span"
			w={`${size}px`}
			h={`${size}px`}
			style={{ display: 'inline-block', verticalAlign: 'middle' }}
		>
			<Lottie animationData={emojiData} loop={loopAnimation} />
		</Box>
	) : (
		<Box
			mx="2px"
			bg="blue_20_opaque"
			borderRadius="tiny"
			w={`${size}px`}
			h={`${size}px`}
			style={{ display: 'inline-block', verticalAlign: 'middle' }}
		>
			<Flex align="center" just="center" h="100%">
				<Icons.Smile size={Math.round(size / 1.7)} strokeWidth={2} color={theme.colors.blue_40} />
			</Flex>
		</Box>
	)
}
