/**
 * Telegram short URL
 */
export const TELEGRAM_MESSAGE_BASE_URL = `https://t.me`

/**
 * Telegram bot name
 */
export const TELEGRAM_BOT_NAME = import.meta.env.NODE_ENV === 'development' ? 'prostposter_dev_bot' : 'prostposter_bot'

// Media
export const MEDIA_ROOT_URL = '/media'
export const MEDIA_TELEGRAM_EMOJI_URL = '/media/assets/telegram_emoji'
export const MEDIA_TELEGRAM_EMOJI_EXT = 'json'

/**
 * Containers to render floating elements outside of the main app container
 */
export const MODALS_ROOT_ID = 'modals-root'
export const FLOATING_UI_ROOT_ID = 'floating-ui-root'
export const MODALS_ROOT_CONTAINER_ID = 'modals-root-container'
export const FLOATING_UI_ROOT_CONTAINER_ID = 'floating-ui-root-container'
