import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import camelcaseKeys from 'camelcase-keys'
import useWebSocket from 'react-use-websocket'
import type { PropsWithChildren } from 'react'

import { notReachable } from '@prostpost/utils'

import { getWebsocketUrl } from 'app/config/websockets'

import { usePostsStore } from 'app/domains/Post/store'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'

import type { WSMessage } from './messages'

export const WithWebsockets = observer(({ children }: PropsWithChildren) => {
	const posts = usePostsStore()
	const channels = useChannelsStore()
	const user = useOnboardedUserStore()

	const { lastJsonMessage } = useWebSocket<WSMessage>(getWebsocketUrl(user.uuid))
	console.log('---1', lastJsonMessage)

	useEffect(() => {
		console.log('---2')
		if (!lastJsonMessage) return
		console.log(`[WS] ${lastJsonMessage.code}`, camelcaseKeys(lastJsonMessage.data))
		console.log('---3')

		switch (lastJsonMessage.code) {
			case 'CHANNEL_TITLE_UPDATE_SUCCEED':
			case 'CHANNEL_AVA_UPDATE_SUCCEED': {
				const { channelName, ...data } = camelcaseKeys(lastJsonMessage.data)
				channels.updateChannel(channelName, data)
				break
			}

			case 'SCHEDULED_POST_PUBLISHED_SUCCEED': {
				const { channelName, ...data } = camelcaseKeys(lastJsonMessage.data)
				posts.updatePublishedPost(data.draftUuid, { publishedAt: data.publishedAt })
				break
			}

			case 'POST_PUBLISHED_VIA_TELEGRAM':
				console.log('---4')
				console.log('TODO: New post published via Telegram: ', lastJsonMessage.data)
				break

			case 'POST_EDITED_FROM_TELEGRAM_NOT_FOUND':
				console.log('---5')
				console.log('TODO: Post edited via Telegram: ', lastJsonMessage.data)
				break

			default:
				console.warn('[WS] Received unknown message: ', lastJsonMessage)
				notReachable(lastJsonMessage)
		}
	}, [lastJsonMessage])

	return children
})
