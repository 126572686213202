import { useState } from 'react'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { HStack, Box } from '@prostpost/uikit'
import { Typography } from '@prostpost/uikit-next'

import { Banner } from '../Banner'

type Props = {
	onClose: () => void
}

export const ProcessingImages = ({ onClose }: Props) => {
	const theme = useTheme()
	const { t } = useTranslation()

	const [isOpen, setIsOpen] = useState(true)
	const onCloseTile = () => {
		setIsOpen(false)
		onClose()
	}

	if (!isOpen) return null

	return (
		<Banner>
			<Box position="absolute" top={1} right={1} p={2} onClick={onCloseTile}>
				<Icons.X size={14} strokeWidth={2.5} color={theme.colors.black_80} onClick={onCloseTile} />
			</Box>

			<HStack space={2} align="center">
				<Icons.ImageDown size={14} strokeWidth={2.5} color={theme.colors.black_80} />
				<Box mt="-1px">
					<Typography variant="heading" color="common_black" size={5}>
						{t('content:feedBanners.imagesProcessing.title', 'Processing images and custom emojis')}
					</Typography>
				</Box>
			</HStack>

			<Typography variant="paragraph" color="common_black" size={12}>
				{t(
					'content:feedBanners.imagesProcessing.desc',
					'We download assets for existing posts in the background to reduce waiting time. This may take a while but you can continue using the app.',
				)}
			</Typography>
		</Banner>
	)
}
