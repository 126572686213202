import styled from '@emotion/styled'
import { css, useTheme } from '@emotion/react'
import * as Icons from 'lucide-react'

import { HStack } from '@prostpost/uikit'

const Image = styled.div<{
	image: string
	more?: number
}>`
	position: relative;

	width: 48px;
	height: 48px;

	background: url(${({ image }) => image});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	border-radius: ${({ theme }) => theme.radii.small};

	${({ more, theme }) =>
		!more
			? ''
			: css`
					overflow: hidden;

					&::after {
						content: '${'+' + more.toString()}';

						position: absolute;
						top: 0;
						left: 0;
						display: flex;
						justify-content: center;
						align-items: center;

						width: 100%;
						height: 100%;

						font-size: 20px;
						font-weight: 600;

						color: ${theme.colors.white_100};
						background: rgb(0 0 0 / 50%);
					}
				`}
`

export const Images = ({ images = [] }: { images: string[] }) => {
	return (
		<HStack space={2} mt={2} align={images.length === 1 ? 'flex-start' : 'center'}>
			{images.length === 1 ? (
				<Image image={images[0]} />
			) : (
				images
					.slice(0, 7)
					.map((image, index) => (
						<Image
							key={image}
							more={index === 6 && images.length > 6 ? images.length - 7 : 0}
							image={image}
						/>
					))
			)}
		</HStack>
	)
}

export const ImagesHistory = ({ image, more }: { image: string; more: number }) => {
	return (
		<HStack space={2} mt={!more ? 2 : 0} align={!more ? 'flex-start' : 'center'}>
			{more ? (
				<>
					<Image image={image} />
					<Image image={image} more={more} />
				</>
			) : (
				<Image image={image} />
			)}
		</HStack>
	)
}

const NotLoadedImage = styled.div<{
	more?: number
}>`
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 48px;
	height: 48px;

	background: #f3f7ff;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	border: 1px solid #d9e6ff;
	border-radius: ${({ theme }) => theme.radii.small};

	${({ more, theme }) =>
		!more
			? ''
			: css`
					overflow: hidden;

					&::after {
						content: '${'+' + more.toString()}';

						position: absolute;
						top: 0;
						left: 0;
						display: flex;
						justify-content: center;
						align-items: center;

						width: 100%;
						height: 100%;

						font-size: 20px;
						font-weight: 600;

						color: ${theme.colors.white_100};
						background: rgb(0 0 0 / 50%);
					}
				`}
`

export const ImagesNotLoaded = ({ imagesCount, hasText }: { imagesCount: number; hasText: boolean }) => {
	const theme = useTheme()
	return (
		<HStack space={2} mt={hasText && imagesCount > 1 ? 0 : 2} align={imagesCount === 1 ? 'flex-start' : 'center'}>
			{imagesCount === 1 ? (
				<NotLoadedImage>
					<Icons.Image size={16} strokeWidth={2} color={theme.colors.blue_40} />
				</NotLoadedImage>
			) : (
				Array.from({ length: imagesCount }, (_, i) => i + 1)
					// we only load first 3 images for history posts to save limits
					.slice(0, 4)
					.map((image, index) => (
						<NotLoadedImage key={image} more={index === 3 && imagesCount > 3 ? imagesCount - 4 : 0}>
							<Icons.Image size={16} strokeWidth={2} color={theme.colors.blue_40} />
						</NotLoadedImage>
					))
			)}
		</HStack>
	)
}
